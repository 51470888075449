<template>
  <div class='picture-wrap' v-if='noteInfo.type === 6' :style='windowInfo'>
    <div class='picture-head'>
      <van-nav-bar @click-left='imgClickLeft'>
        <template #title>
          <div class='iconfont log'>{{ imagesIndex }}/{{ images.length }}</div>
        </template>
        <template #left>
          <span class='iconfont ico'>&#xe60b; </span>
        </template>
        <template #right>
          <span class='iconfont ico'>&#xe691; </span>
        </template>
      </van-nav-bar>
    </div>
    <van-swipe @change='onChange' :show-indicators='false' class='view-picture'>
      <van-swipe-item v-for='img in images' :key='img.url'>
        <div class='loop-root'>
          <img class='picture' :src='img.url' alt=''>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class='images-titel'>{{ noteInfo.title }}</div>
  </div>
</template>
<script>
export default {
  props:{
    noteInfo:{
      type: Object,
      default: () => {}
    },
    images:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imagesIndex: 1,
      windowInfo: {
        width: '100%',
        height: ''
      },
    }
  },
  mounted() {
    this.getWindowInfo()
  },
  methods:{
    imgClickLeft() {
      this.$router.go(-1);
    },
    onChange(index) {
      this.imagesIndex = index + 1
    },
    getWindowInfo() {
      this.windowInfo.height = document.documentElement.clientHeight + 'px'
    }
  },
  watch: {
    '$route'() {
      this.getWindowInfo()
    }
  },
}
</script>

<style lang="less" scoped>

  .picture-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #000000;
    position: relative;
    overflow: hidden;

    .van-nav-bar {
      background: #000000;

      .log {
        color: #ffffff;
        font-size: 16px;
      }

      .ico {
        color: #ffffff;
        font-size: 24px;
      }
    }

    .view-picture {
      width: 100%;
      max-height: 100%;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);

      .van-swipe__track {
        align-items: center;
      }

      .loop-root {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .picture {
        width: 100%;
        height: auto;
        flex: 1;
        vertical-align: middle;
        object-fit: cover;
        display: inline-block;
      }
    }

    .images-titel {
      padding: 0 20px;
      position: absolute;
      bottom: 110px;
      color: #ffffff;
      font-size: 16px;
      font-family: Source Han Sans, Source Han Sans-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 24px;
      letter-spacing: 0px;
    }
  }
</style>